import store from '../store'

const BASE_URL = process.env.VUE_APP_BASE_URL;


export default function authHeader() {

    if (store.state.auth.token) {
        const base = {
            baseURL: BASE_URL,
            headers: {
                Authorization: `JWT ${store.state.auth.token}`,
                "Content-Type": "application/json"
            },
            xhrFields: {
                withCredentials: true
            }
        };
        return base;

    } else {
        return {};
    }
}
