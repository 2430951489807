<template>
  <v-container fluid>
    <head-title
      text="Pagos de Ordenes"
      :to="{ name: 'accounting-register-buy-home' }"
    ></head-title>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="3">
        <company-name
          v-model="companyId"
          @value="(val) => (companyId = val)"
          :returnObj="false"
          required
        ></company-name>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="[
            { text: 'Por mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
            { text: 'Por Período', value: 2 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 1">
        <date-field label="Fecha" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-else-if="searchType === 0">
        <date-field label="Fecha" v-model="date" type="month"></date-field>
      </v-col>
      <v-row v-else-if="searchType === 2">
        <v-col cols="12" md="6">
          <date-field label="De" v-model="date"></date-field>
        </v-col>
        <v-col cols="12" md="6">
          <date-field label="Hasta" v-model="until"></date-field>
        </v-col>
      </v-row>
      <v-col cols="12" md="2">
        <provider-field v-model="providerId"></provider-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <v-select
          :items="[
            { text: 'Contado/Crédito', value: 'INVOICE' },
            { text: 'Contravale', value: 'VOUCHER' },
            { text: 'Sin Documento', value: 'N/A' },
            { text: 'Nota de Crédito', value: 'NCRE' },
          ]"
          label="Forma de Pago"
          v-model="payment_type"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="[
            { text: 'Finalizada', value: true },
            { text: 'Pendientes', value: false },
          ]"
          label="Estado"
          v-model="status"
        ></v-select>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn class="mx-1" fab dark small color="indigo" @click="search()">
          <v-icon dark> mdi-database-search</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="indigo"
          :to="{ name: 'accounting-payment_create' }"
        >
          <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="payments"
      :loading="load"
      :search="filter"
      hide-default-footer
      disable-sort
      disable-pagination
      class="elevation-1"
      no-results-text="No hay datos disponibles"
    >
      <template v-slot:top v-if="payments.length > 0 && payments != undefined">
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <v-btn text color="primary" @click="showDetail(item.id)">
          {{ item.code }}
        </v-btn>
      </template>
      <template v-slot:[`item.provider.name`]="{ item }">
        <span v-if="item.provider == null">VARIOS</span>
        <span v-else>{{item.provider.name}}</span>
      </template>
      <template v-slot:[`item.payment_type`]="{ item }">
        <span v-if="item.payment_type == 'N/A'">N/A</span>
        <span v-else-if="item.payment_type == 'VOUCHER'">CONTRAVALE</span>
        <span v-else-if="item.payment_type == 'NCRE'">NOTA CRÉDITO</span>
        <span v-else-if="item.payment_type == 'CJCH'">CAJA CHICA</span>
        <span v-else>CONTADO/CRÉDITO</span>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | date }}
      </template>
      <template v-slot:[`item.account`]="{ item }">
        <span v-if="item.account != null">
          {{ item.account.bank.name }}-{{ item.account.code }}
        </span>
      </template>
      <template v-slot:[`item.is_confirmed`]="{ item }">
        <span v-if="item.is_confirmed || item.floating_check">Pagado</span>
        <span v-else>En proceso de pago</span>
        <span></span>
      </template>
    </v-data-table>
    <detail-payment
      :dialog="dialog"
      :paymentId="paymentId"
      v-if="dialog"
      @close="(dialog = false), (paymentId = 0)"
      @refresh="search()"
    ></detail-payment>
    <v-pagination
      v-model="currentPage"
      :total-visible="10"
      :length="totalPages"
      :disabled="loading"
      v-show="totalPages > 0"
    ></v-pagination>
  </v-container>
</template>
<script>
import request from "@/services/requests";
export default {
  name: "PaymentList",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    CompanyName: () => import("@/components/fields/CompanyField"),
    DateField: () => import("@/components/fields/DateField"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    DetailPayment: () => import("@/components/accounting/DetailPayment"),
  },
  data() {
    return {
      filter: "",
      load: false,
      dialog: false,
      paymentId: 0,
      title: "",
      totalPages: 0,
      currentPage: 1,
      loading: false,
      companyId: undefined,
      providerId: undefined,
      date: "",
      until: "",
      searchType: "",
      status: undefined,
      uri: "",
      payment_type: undefined,
      payments: [],
      headers: [
        { text: "No.", value: "code" },
        { text: "Empresa", value: "company.name" },
        { text: "Proveedor", value: "provider.name" },
        { text: "Forma de Pago", value: "payment_type" },
        { text: "Fecha", value: "created" },
        { text: "Cuenta", value: "account" },
        { text: "Total", value: "amount" },
        { text: "Estado", value: "is_confirmed" },
      ],
    };
  },
  methods: {
    getPayments(uri) {
      if (uri != undefined) {
        this.load = true;
        request.get(uri).then((res) => {
          this.payments = res.data.results;
          this.totalPages = res.data.total_pages;
        });
        this.load = false;
      }
    },
    search() {
      if (this.companyId == undefined) {
        this.$toasted.global.error({
          message: "Seleccione una Empresa",
        });
        return;
      }
      this.uri =
        "api/accounting/?page=" + this.currentPage + "&company_id=" + this.companyId;
      if (this.searchType === 1) {
        this.uri += "&created_date=" + this.date;
      } else if (this.searchType === 0) {
        this.uri +=
          "&month=" + this.date.substring(5, 7) + "&year=" + this.date.substring(0, 4);
      } else if (this.searchType == 2) {
        this.uri += "&date_min=" + this.date + "&date_max=" + this.until;
      }

      if (this.providerId != undefined) {
        this.uri += "&provider=" + this.providerId.id;
      }
      if (this.payment_type != undefined) {
        this.uri += "&payment_type=" + this.payment_type;
      }
      if (this.status != undefined) {
        if(this.status){
          this.uri += "&is_finished_payement=" + this.status;
        } else{
          this.uri += "&is_pending_payment=" + this.status;
        }
      }
      this.getPayments(this.uri);
    },
    showDetail(item) {
      this.paymentId = item;
      this.dialog = true;
    },
    resetCurrentPage() {
      this.currentPage = 1;
    },
  },
  mounted() {
    this.searchType = 0;
    this.date = new Date().toISOString().substring(0, 10);
    this.getPayments();
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
      this.resetCurrentPage();
    },
    searchType() {
      this.resetCurrentPage();
    },
    providerId() {
      this.resetCurrentPage();
    },
    payment_type() {
      this.resetCurrentPage();
    },
    status() {
      this.resetCurrentPage();
    },
    currentPage: function () {
      this.search();
    },
  },
};
</script>
