import axios from 'axios';
import authHeader from './auth-header';
import handleErrors from './handleErrors';

class GenericRequest {
	get(uri) {
		const axiosInstance = axios.create(authHeader());
		return axiosInstance({
			url: uri,
			method: 'get'
		})
			.then((response) => {
				return response;
			})
			.catch((e) => {
				handleErrors(e);
				if (e.response === undefined) {
					return { response: { status: 503 } };
				} else {
					return e.response;
				}
			});
	}
	post(uri, data) {
		const axiosInstance = axios.create(authHeader());
		return axiosInstance({
			url: uri,
			method: 'post',
			data: data
		})
			.then((response) => {
				return response;
			})
			.catch((e) => {
				handleErrors(e);
				return e.response;
			});
	}
	postBlob(uri, data) {
		const axiosInstance = axios.create(authHeader());
		return axiosInstance({
			url: uri,
			method: 'post',
			responseType: 'blob',
			data: data
		})
			.then((response) => {
				return response;
			})
			.catch((e) => {
				handleErrors(e);
				return e.response;
			});
	}

	patch(uri, data) {
		const axiosInstance = axios.create(authHeader());
		return axiosInstance({
			url: uri,
			method: 'patch',
			data: data
		})
			.then((response) => {
				return response;
			})
			.catch((e) => {
				handleErrors(e);
				return e.response;
			});
	}

	delete(uri) {
		const axiosInstance = axios.create(authHeader());
		return axiosInstance({
			url: uri,
			method: 'delete'
		})
			.then((response) => {
				return response;
			})
			.catch((e) => {
				handleErrors(e);
				return e.response;
			});
	}
}

export default new GenericRequest();
