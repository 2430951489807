var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('head-title',{attrs:{"text":"Pagos de Ordenes","to":{ name: 'accounting-register-buy-home' }}}),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('company-name',{attrs:{"returnObj":false,"required":""},on:{"value":function (val) { return (_vm.companyId = val); }},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
          { text: 'Por mes', value: 0 },
          { text: 'Por Fecha', value: 1 },
          { text: 'Por Período', value: 2 } ],"label":"Mostrar Datos"},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),(_vm.searchType === 1)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Fecha"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):(_vm.searchType === 0)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Fecha","type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):(_vm.searchType === 2)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('date-field',{attrs:{"label":"De"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('date-field',{attrs:{"label":"Hasta"},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('provider-field',{model:{value:(_vm.providerId),callback:function ($$v) {_vm.providerId=$$v},expression:"providerId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
          { text: 'Contado/Crédito', value: 'INVOICE' },
          { text: 'Contravale', value: 'VOUCHER' },
          { text: 'Sin Documento', value: 'N/A' },
          { text: 'Nota de Crédito', value: 'NCRE' } ],"label":"Forma de Pago","clearable":""},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":[
          { text: 'Finalizada', value: true },
          { text: 'Pendientes', value: false } ],"label":"Estado"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.search()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-database-search")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo","to":{ name: 'accounting-payment_create' }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payments,"loading":_vm.load,"search":_vm.filter,"hide-default-footer":"","disable-sort":"","disable-pagination":"","no-results-text":"No hay datos disponibles"},scopedSlots:_vm._u([(_vm.payments.length > 0 && _vm.payments != undefined)?{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]},proxy:true}:null,{key:"item.code",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.showDetail(item.id)}}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.provider.name",fn:function(ref){
        var item = ref.item;
return [(item.provider == null)?_c('span',[_vm._v("VARIOS")]):_c('span',[_vm._v(_vm._s(item.provider.name))])]}},{key:"item.payment_type",fn:function(ref){
        var item = ref.item;
return [(item.payment_type == 'N/A')?_c('span',[_vm._v("N/A")]):(item.payment_type == 'VOUCHER')?_c('span',[_vm._v("CONTRAVALE")]):(item.payment_type == 'NCRE')?_c('span',[_vm._v("NOTA CRÉDITO")]):(item.payment_type == 'CJCH')?_c('span',[_vm._v("CAJA CHICA")]):_c('span',[_vm._v("CONTADO/CRÉDITO")])]}},{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created))+" ")]}},{key:"item.account",fn:function(ref){
        var item = ref.item;
return [(item.account != null)?_c('span',[_vm._v(" "+_vm._s(item.account.bank.name)+"-"+_vm._s(item.account.code)+" ")]):_vm._e()]}},{key:"item.is_confirmed",fn:function(ref){
        var item = ref.item;
return [(item.is_confirmed || item.floating_check)?_c('span',[_vm._v("Pagado")]):_c('span',[_vm._v("En proceso de pago")]),_c('span')]}}],null,true)}),(_vm.dialog)?_c('detail-payment',{attrs:{"dialog":_vm.dialog,"paymentId":_vm.paymentId},on:{"close":function($event){(_vm.dialog = false), (_vm.paymentId = 0)},"refresh":function($event){return _vm.search()}}}):_vm._e(),_c('v-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalPages > 0),expression:"totalPages > 0"}],attrs:{"total-visible":10,"length":_vm.totalPages,"disabled":_vm.loading},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }