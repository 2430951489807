import Vue from 'vue';

export default function handlErrors(error) {
	if (!error.response) {
		Vue.toasted.global.error({
			message: 'No se pudo establecer conexión con el servidor'
		});
	} else if (error.response.status === 401) {
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		Vue.toasted.global.info({
			message: 'La sesión ha expirado'
		});
		location.reload();
	} else if (error.response.status === 500) {
		Vue.toasted.global.info({
			message: 'Se ha producido un error inesperado.'
		});
	} else if (error.response.status === 403) {
		Vue.toasted.global.info({
			message: 'No tiene permiso para realizar la operación solicitada.'
		});
	}
}
